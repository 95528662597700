<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icon }}
      </v-icon>
      <span class="text-break">{{ title }}</span>
    </v-card-title>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <!-- ============== -->
        <!-- ID and Status -->
        <!-- ============== -->
        <v-row>
          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="enrollmentData.id"
              :label="idFieldLabel"
              :append-icon="icons.mdiContentCopy"
              dense
              outlined
              :disabled="false"
              :readonly="true"
              @click:append="idToClipboard"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-show="mode === 'update'"
              v-model="enrollmentData.status"
              dense
              outlined
              label="Status *"
              :rules="requiredRule"
              hide-details="auto"
              :items="status"
              :disabled="isStatusUpdateDisabled()"
            ></v-select>
          </v-col>
        </v-row>

        <!-- ============== -->
        <!-- Factor Type -->
        <!-- ============== -->
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            Select the type of factor:
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="enrollmentData.factor"
              :disabled="mode === 'update'"
              dense
              outlined
              label="Factor *"
              :rules="requiredRule"
              hide-details="auto"
              :items="factors"
              item-text="label"
              item-value="id"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            Give it a meaningful label to you recognize the factor in case you have multiple enrollments of the same type. It's optional but recommended.
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="enrollmentData.label"
              hide-details="auto"
              label="Label"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- ============== -->
          <!-- ID specific fields -->
          <!-- ============== -->
          <v-col
            v-show="!!factor && factor.subtype === 'secret:id' && mode === 'create'"
            cols="12"
            md="6"
          >
            Enter username and username confirmation:
          </v-col>

          <v-col
            v-show="!!factor && factor.subtype === 'secret:id' && mode === 'create'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="enrollmentData.input_id"
              hide-details="auto"
              label="Username *"
              :rules="regexRule"
              dense
              outlined
            ></v-text-field>
            <!-- <small>(Must match regular expression: {{ factor.config.regex }})</small> -->
          </v-col>
          <v-col
            v-show="!!factor && factor.subtype === 'secret:id' && mode === 'create'"
            cols="12"
            md="6"
          />
          <v-col
            v-show="!!factor && factor.subtype === 'secret:id' && mode === 'create'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="enrollmentData.input_id_confirmation"
              hide-details="auto"
              label="Username Confirmation *"
              :rules="regexRule"
              dense
              outlined
            ></v-text-field>
            <!-- <small>(Must match regular expression: {{ factor.config.regex }})</small> -->
          </v-col>
          <!--
          <v-col
            v-show="!!factor && factor.subtype === 'id' && mode === 'create'"
            cols="12"
            md="6"
          >
            The chosen username must meet the follow syntax pattern (regular expression):<br />
          </v-col>
          <v-col
            v-if="!!factor && factor.subtype === 'id' && mode === 'create'"
            md="6"
            cols="12"
          >
            <strong>{{ factor.config.regex }}</strong>
          </v-col>
          -->

          <!-- ============== -->
          <!-- Password specific fields -->
          <!-- ============== -->
          <v-col
            v-show="!!factor && factor.subtype === 'secret:password' && mode === 'create'"
            cols="12"
            md="6"
          >
            Enter password and password confirmation:
          </v-col>

          <v-col
            v-show="!!factor && factor.subtype === 'secret:password' && mode === 'create'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="enrollmentData.input_password"
              hide-details="auto"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password *"
              :rules="regexRule"
              dense
              outlined
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <!-- <small>(Must match regular expression: {{ factor.config.regex }})</small> -->
          </v-col>
          <v-col
            v-show="!!factor && factor.subtype === 'secret:password' && mode === 'create'"
            cols="12"
            md="6"
          />
          <v-col
            v-show="!!factor && factor.subtype === 'secret:password' && mode === 'create'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="enrollmentData.input_password_confirmation"
              hide-details="auto"
              :type="isPasswordConfirmationVisible ? 'text' : 'password'"
              label="Password Confirmation *"
              :rules="regexRule"
              dense
              outlined
              :append-icon="isPasswordConfirmationVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
            ></v-text-field>

            <div v-if="analysis && analysis.warning">
              <small>{{ analysis.warning }}</small>
            </div>
            <div v-if="analysis && analysis.suggestions && analysis.suggestions.length > 0">
              <small>
                Suggestions:<br />
                <!-- eslint-disable-next-line -->
                <div v-html="arrayToHtml(analysis.suggestions)">
                </div>
              </small>
            </div>
            <!-- <small>(Must match regular expression: {{ factor.config.regex }})</small> -->
          </v-col>
          <!--
          <v-col
            v-show="!!factor && factor.subtype === 'password' && mode === 'create'"
            cols="12"
            md="6"
          >
            The chosen password must meet the following syntax pattern (regular expression):<br />
          </v-col>
          <v-col
            v-show="!!factor && factor.subtype === 'password' && mode === 'create'"
            md="6"
            cols="12"
          >
            <strong>{{ factor.config.regex }}</strong>
          </v-col>
          -->

          <!-- ============== -->
          <!-- TOTP specific fields -->
          <!-- ============== -->
          <v-col
            v-show="enrollmentData.subtype === 'totp' && enrollmentData.config.secret && enrollmentData.status === 'PENDING'"
            cols="12"
            md="6"
          >
            Scan the QR code with your authenticator app (Google Authenticator, Authy, etc.) and enter the TOTP as confirmation, or enter the setup key manually: <strong>{{ enrollmentData.config.secret }}</strong>
          </v-col>

          <v-col
            v-show="enrollmentData.subtype === 'totp' && enrollmentData.config.url && enrollmentData.status === 'PENDING'"
            cols="12"
            md="6"
          >
            <img v-if="qrcode" :src="qrcode" /><br />
          </v-col>

          <v-col
            v-show="!!factor && factor.subtype === 'totp' && enrollmentData.status === 'PENDING'"
            cols="12"
            md="6"
          >
            Please enter the <strong>6-digit code (TOTP)</strong> from your authenticator app (Google Authenticator, Authy, etc.)
          </v-col>
          <v-col
            v-show="enrollmentData.subtype === 'totp' && enrollmentData.config.url && enrollmentData.status === 'PENDING'"
            md="6"
            cols="12"
          >
            <!-- TOTP (numeric 6-digit) -->
            <v-otp-input
              id="otp-input"
              v-model="enrollmentData.input_password"
              autocomplete="off"
              length="6"
              type="number"
              @finish="onFinishTOTP"
            ></v-otp-input>
          </v-col>

          <!-- ============== -->
          <!-- OTP specific fields -->
          <!-- ============== -->
          <v-col
            v-show="!!factor && factor.subtype === 'otp' && mode === 'create'"
            cols="12"
            md="6"
          >
            Enter email and email confirmation:
          </v-col>

          <v-col
            v-show="!!factor && factor.subtype === 'otp' && mode === 'create'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="enrollmentData.input_otp"
              hide-details="auto"
              label="Email *"
              :rules="regexRule"
              :disabled="enrollmentData.status === 'PENDING'"
              dense
              outlined
            ></v-text-field>
            <!-- <small>(Must match regular expression: {{ factor.config.regex }})</small> -->
          </v-col>
          <v-col
            v-show="!!factor && factor.subtype === 'otp' && mode === 'create'"
            cols="12"
            md="6"
          />
          <v-col
            v-show="!!factor && factor.subtype === 'otp' && mode === 'create'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="enrollmentData.input_otp_confirmation"
              hide-details="auto"
              label="Email Confirmation *"
              :rules="regexRule"
              :disabled="enrollmentData.status === 'PENDING'"
              dense
              outlined
            ></v-text-field>
            <!-- <small>(Must match regular expression: {{ factor.config.regex }})</small> -->
          </v-col>

          <v-col
            v-show="!!factor && factor.subtype === 'otp' && enrollmentData.status === 'PENDING'"
            cols="12"
            md="6"
          >
            A <strong>One-Time Password</strong> is sent to your email address: <strong>{{ customer ? customer.email : '' }}</strong><br />
            Please enter it as verification.<br />
          </v-col>
          <v-col
            v-show="enrollmentData.subtype === 'otp' && enrollmentData.status === 'PENDING'"
            md="6"
            cols="12"
          >
            <!-- OTP (alpha-numeric 6-digit) -->
            <v-otp-input
              id="otp-input"
              v-model="enrollmentData.input_password"
              autocomplete="off"
              length="6"
              type="text"
              @finish="onFinishOTP"
            ></v-otp-input>
          </v-col>

          <!-- ============== -->
          <!-- OAuth2 specific fields -->
          <!-- ============== -->
          <!--
          <v-col
            v-show="!!factor && factor.subtype && factor.subtype.startsWith('oauth2') && enrollmentData.status === 'PENDING'"
            cols="12"
            md="6"
          >
            <a @click.prevent="$emit('oauth2-authorization-event')">Re-Open Google Authorization URL</a>
          </v-col>
          <v-col
            v-show="enrollmentData.subtype && enrollmentData.subtype.startsWith('oauth2') && enrollmentData.status === 'PENDING'"
            md="6"
            cols="12"
          >
          </v-col>
          -->
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiFormSelect,
  mdiWavesArrowRight, mdiKey, mdiContentCopy,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'
import { ref } from '@vue/composition-api'

export default {
  /**
   * props
   */
  props: {
    factors: {
      type: Array[Object],
      default: () => [],
    },
    analysis: {
      type: Object,
      default: () => {},
    },
    enrollmentData: {
      type: Object,
      default: () => {},
    },
    factor: {
      type: Object,
      default: () => ({ config: {} }),
    },
    customer: {
      type: Object,
      default: () => {},
    },
    qrcode: { type: String, default: undefined },
    type: { type: String, default: undefined },
    mode: { type: String, default: undefined },
  },

  /**
   * data
   */
  data() {
    return {
      requiredRule: [
        value => !!value || 'Required',
      ],
      regexRule: [
        value => (!!value && this.regex() && this.regex().test(value)) || `Must match regular expression ${this.factor.config.regex}`,
      ],
    }
  },

  /**
   * watch
   */
  watch: {

    /**
     * enrollmentData
     */
    enrollmentData() {
      if (this.enrollmentData.status === 'PENDING') {
        this.status = [
          { text: 'Enabled', value: 'ENABLED' },
          { text: 'Disabled', value: 'DISABLED' },
          { text: 'Pending', value: 'PENDING' },
        ]
      } else {
        this.status = [
          { text: 'Enabled', value: 'ENABLED' },
          { text: 'Disabled', value: 'DISABLED' },
        ]
      }
    },
  },

  /*
  computed: {
    regex() {
      if (this.factor?.config?.regex) {
        return new RegExp(this.factor.config.regex.trim())
      }

      return undefined
    },
  },
  */

  /**
   * methods
   */
  methods: {

    /**
     * arrayToHtml
     */
    arrayToHtml(arr) {
      let html = '<ul>'
      for (let i = 0; i < arr.length; i += 1) {
        html = html.concat('<li>').concat(arr[i]).concat('</li>')
      }
      html = html.concat('</ul>')

      return html
    },

    /**
     * isStatusUpdateDisabled
     */
    isStatusUpdateDisabled() {
      return this.enrollmentData.status === 'PENDING'
    },

    /**
     * regex
     */
    regex() {
      if (this.factor?.config?.regex) {
        return new RegExp(this.factor.config.regex.trim())
      }

      return undefined
    },

    /**
     * df
     */
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * idToClipboard
     */
    idToClipboard() {
      QuasrHelper.copyTextToClipboard(this.enrollmentData.id)
    },

    /**
     * onFinishTOTP
     */
    onFinishTOTP(totp) {
      this.enrollmentData.input_password = totp
      this.$emit('totp-verify-event')
    },

    /**
     * onFinishOTP
     */
    onFinishOTP(otp) {
      this.enrollmentData.input_password = otp
      this.$emit('otp-verify-event')
    },
  },

  /**
   * setup
   */
  setup(props) {
    const title = props.type === 'enrollment' ? 'Enrollment Settings' : 'Factor Settings'
    const idFieldLabel = props.type === 'enrollment' ? 'Enrollment ID (read-only)' : 'Factor ID(read-only)'
    const icon = mdiKey
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
      { text: 'Pending', value: 'PENDING' },
    ]

    return {
      title,
      idFieldLabel,
      icon,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      status,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiWavesArrowRight,
        mdiKey,
        mdiContentCopy,
      },
    }
  },
}
</script>
